const dev = {
  url: {
    REACT_APP_DOMAIN: `https://api-dev.belemdopara.org`,
    REACT_APP_FLASK: `https://api2-dev.belemdopara.org`,
  },
};

const prod = {
  url: {
    REACT_APP_DOMAIN: `https://api.belemdopara.org`,
    REACT_APP_FLASK: `https://api2.belemdopara.org`,
  },
};

const localdev = {
  url: {
    REACT_APP_DOMAIN: /* `http://localhost:8080` */ `https://api-dev.belemdopara.org`,
    REACT_APP_FLASK: /* `http://localhost:4000` */ `https://api2-dev.belemdopara.org`,
  },
};

let configEnv;
if (process.env.REACT_APP_NODE_ENV == "prod") {
  configEnv = prod;
} else if (process.env.REACT_APP_NODE_ENV == "dev") {
  configEnv = dev;
} else {
  configEnv = localdev;
}
export const config = configEnv;
